import React from "react";
import Layout from "../components/layout";
import Link from 'gatsby-link';
import "../styles/pages/notfound.scss";

const ErrorPage = () => (
    <Layout>
        <div className="not-found container">
            <div className="inner">
                <h1>You just broke the internet!</h1>
                <p>404, my virtual hamsters could not find the page you were looking for.</p>
                <Link to="/">Go to Home</Link>
            </div>
        </div>
    </Layout>
)

export default ErrorPage

